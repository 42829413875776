export default [
  {
    header: 'Content Admin',
    icon: 'FileTextIcon',
    route: 'content-list',
    children: [
      {
        title: 'Contents',
        route: 'content-list',
        icon: 'FileTextIcon',
      },
      {
        title: 'Tasks',
        route: 'task-list',
        icon: 'ActivityIcon',
      },
      {
        title: 'Teams',
        route: 'team-list',
        icon: 'UsersIcon',
      },
      {
        title: 'Sets',
        route: 'set-list',
        icon: 'BoxIcon',
      },
      {
        title: 'Goals',
        route: 'goals-list',
        icon: 'TargetIcon',
      },
      {
        title: 'Charity',
        route: 'charity-list',
        icon: 'GiftIcon',
      },
    ],
  },
]
